.demo-logo-vertical {
    /* height: 32px; */
    margin: 10px;
    border-radius: 6px;
    justify-content: center;
    align-content: left;
    display: flex;
}

.demo-logo-vertical img {
    height: 37px;
    object-fit: contain;
}

.teams {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.team {
    padding: 20px;
    width: 200px;
    border-radius: 5px;
}

.player-card {
    background-color: #6200ea;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.players {
    display: flex;
    justify-content: space-around;
}

.svg_background {
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}


.green-row {
    background-color: rgb(80, 80, 80);
    color:yellow;


  }

  .green-row:hover {
    color:rgb(255, 0, 0);
  }
  